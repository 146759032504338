<template>
  <!-- 新建赛程 -->
  <div class="addRace">
    <div>
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="medium"
        label-width="100px"
      >
        <!-- <el-form-item
          label-width="128px"
          label="比赛名称（项目）"
          prop="competitionId"
        >
          <el-select
            v-model="formData.competitionId"
            placeholder="请选择比赛名称（项目）"
            clearable
            :style="{ width: '30%' }"
          >
            <el-option
              v-for="(item, index) in CompetitionDropList"
              :key="index"
              :label="item.name"
              :value="item.id"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <div class="fileBox">
          <el-row
            v-for="(item, index) in formData.scheduleDtoList"
            :key="index"
            :gutter="15"
            class="rowBox"
          >
            <div
              v-if="
                item.scheduleName !== 4 &&
                item.scheduleName !== 5 &&
                item.scheduleName !== 6
              "
            >
              <el-form-item class="item1" label="赛程阶段">
                <el-select
                  v-model="item.scheduleName"
                  placeholder="请选择赛程阶段"
                  clearable
                  :style="{ width: '30%' }"
                >
                  <el-option
                    v-for="(item, index) in options"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                    :disabled="item.disabled"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="射击发数" prop="shootCount">
                <!-- <el-input
                  v-model="item.shootCount"
                  placeholder="请输入射击发数"
                  clearable
                  :style="{ width: '30%' }"
                >
                </el-input> -->
                <el-input-number
                  v-model="item.shootCount"
                  :style="{ width: '10%' }"
                  :precision="0"
                  :max="600"
                  :min="1"
                >
                </el-input-number>
              </el-form-item>
              <el-form-item label="记成绩发数">
                <!-- <el-input
                  v-model="item.scoreCount"
                  placeholder="请输入记成绩发数"
                  clearable
                  :style="{ width: '30%' }"
                >
                </el-input> -->
                <el-input-number
                  v-model="item.scoreCount"
                  :style="{ width: '10%' }"
                  :precision="0"
                  :max="item.shootCount"
                  :min="1"
                >
                </el-input-number>
              </el-form-item>
              <el-form-item label="比赛限时(分钟)" prop="ompetitionTime">
                <!-- <el-input
                  v-model="item.ompetitionTime"
                  placeholder="请输入比赛限时"
                  clearable
                  :style="{ width: '30%' }"
                >
                </el-input> -->
                <el-input-number
                  v-model="item.ompetitionTime"
                  :style="{ width: '10%' }"
                  :precision="0"
                  :max="600"
                  :min="30"
                >
                </el-input-number>
              </el-form-item>
              <el-form-item label="比赛时间">
                <el-date-picker
                  type="datetimerange"
                  v-model="item.timeStol"
                  placement="bottom-start"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm"
                  :style="{ width: '30%' }"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  range-separator="至"
                  clearable
                  :default-time="['9:00:00', '18:00:00']"
                  :picker-options="setDateRange"
                ></el-date-picker>
              </el-form-item>
            </div>
            <div
              v-if="
                formData.scheduleDtoList.length > 1 &&
                item.scheduleName !== 4 &&
                item.scheduleName !== 5 &&
                item.scheduleName !== 6
              "
              class="myline"
              style="
                height: 1px;
                width: 35%;
                margin: 20px;
                border-bottom: 1px solid #000;
              "
            ></div>
            <el-button
              class="addBtnBox"
              v-if="index + 1 === formData.scheduleDtoList.length"
              type="primary"
              @click="addRow"
              >新增</el-button
            >
            <el-button
              class="delBtnBox"
              v-if="formData.scheduleDtoList.length > 1"
              type="primary"
              @click="delRow(index)"
              >删除</el-button
            >
          </el-row>
        </div>

        <el-form-item class="btnBox" size="large">
          <el-button class="resBox" @click="resetForm">取消</el-button>
          <el-button
            type="primary"
            class="subBox"
            @click="submitForm"
            :loading="loading"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { updateSchedule, getSelectScheduleDetails } from '@/api/eventManagement/raceSchedule/index.js'
export default {
  name: 'AddRace',
  inject: ['reload'],
  components: {},
  props: {},
  data() {
    return {
      setDateRange: {
        disabledDate: time => {
          // 只能选择当前 1 年内的时间
          const year = 365 * 24 * 3600 * 1000;
          // 只能选择当前 1 月的时间
          const month = 30 * 24 * 3600 * 1000;
          // 只能选择当前 1 周的时间
          const week = 7 * 24 * 3600 * 1000;
          // 返回小于当前日期并近【1年】【1月】【1周】内的日期
          // 注意：这是从当前日期开始往前算，如果当前日期可选的话，不要写【-8.64e7】
          // return time.getTime() > Date.now() || time.getTime() < (Date.now() - 8.64e7) - year;
          // 禁用今天之前的日期【当前天可选】
          return time.getTime() < Date.now() - 8.64e7;
          // 禁用今天之前的日期【当前天不可选】
          // return time.getTime() < Date.now();
          // 禁用今天之后的日期【当前天不可选】
          // return time.getTime() > Date.now() - 8.64e7;
          // 禁用今天之后的日期【当前天可选】
          // return time.getTime() > Date.now();
        }
      },
      formData: {
        competitionId: parseInt(sessionStorage.getItem('competitionId')),
        scheduleDtoList: [
          {
            endTime: "", // 结束时间
            ompetitionTime: null, // 比赛限时，单位s
            scheduleId: null, // 赛程id
            scheduleName: null, // 1淘汰赛2资格赛3半决赛4决赛
            shootCount: null, // 射击发数
            scoreCount: null, // 每多少发记一次成绩
            startTime: "", // 开始时间
            timeStol: null // 时间段
          }
        ]
      },
      loading: false,
      options: [
        {
          id: 1,
          name: '淘汰赛'
        },
        {
          id: 2,
          name: '资格赛'
        },
        {
          id: 3,
          name: '决赛'
        }
      ],
      rules: {
        competitionId: [{
          required: true,
          message: '请选择比赛名称（项目）',
          trigger: 'change'
        }],
        scheduleName: [{
          required: true,
          message: '请输入赛程阶段',
          trigger: 'blur'
        }],
        timeStol: [{
          required: true,
          message: '不能为空',
          trigger: 'change'
        }]
      },
      CompetitionDropList: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getSelectScheduleDetails();
  },
  methods: {
    // 根据id查看赛程详情
    async getSelectScheduleDetails() {
      const res = await getSelectScheduleDetails({ competitionId: this.formData.competitionId });
      // console.log(res);
      if (res.status === 200) {
        if (res.data.scheduleDtoList.length > 0) {
          this.formData = res.data;
          for (let i = 0; i < this.formData.scheduleDtoList.length; i++) {
            this.formData.scheduleDtoList[i].timeStol = [this.formData.scheduleDtoList[i].startTime, this.formData.scheduleDtoList[i].endTime];
            if (this.formData.scheduleDtoList[i].scheduleName === 3) {
              this.formData.scheduleDtoList.splice(i + 1, 3);
            }
          }
        } else {
          this.formData = {
            competitionId: res.data.competitionId,
            scheduleDtoList: [
              {
                endTime: "", // 结束时间
                ompetitionTime: null, // 比赛限时，单位s
                scheduleId: null, // 赛程id
                scheduleName: null, // 1资格赛2半决赛3决赛
                shootCount: null, // 射击发数
                scoreCount: null, // 每多少发记一次成绩
                startTime: "", // 开始时间
                timeStol: null // 时间段
              }
            ]
          }
        }
      }
      console.log(this.formData);
    },
    // 新增赛程阶段
    addRow() {
      // console.log(this.formData.scheduleDtoList);
      this.formData.scheduleDtoList.push({
        endTime: "", // 结束时间
        ompetitionTime: null, // 比赛限时，单位s
        scheduleId: null, // 赛程id
        scheduleName: null, // 1资格赛2半决赛3决赛
        shootCount: null, // 射击发数
        scoreCount: null, // 每多少发记一次成绩
        startTime: "", // 开始时间
        timeStol: null // 时间段
      });
    },
    // 删除赛程阶段
    delRow(val1) {
      // console.log(val1, this.formData.scheduleDtoList);
      this.$confirm('是否删除该赛程？', '提示', {
        type: 'warning',
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      }).then(() => {
        if (this.formData.scheduleDtoList[val1].scheduleName === 3) {
          this.formData.scheduleDtoList.splice(val1, 4);
        } else {
          this.formData.scheduleDtoList.splice(val1, 1);
        }
      }).catch(() => {
        this.$message({
          showClose: true,
          type: 'info',
          message: '取消删除!'
        });
      })

      // this.formData.scheduleDtoList.pop();
    },
    // 编辑确定
    async submitForm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        // TODO 提交表单
      })
      try {
        this.formData.scheduleDtoList.forEach(item => {
          if (item.scheduleName === null) {
            throw new Error('请选择赛程阶段！');
          }
          if (item.scheduleName !== 3) {
            if (item.shootCount === null) {
              throw new Error('请填写射击发数！');
            }
            if (item.scoreCount === null) {
              throw new Error('请填写记成绩发数！');
            }
            if (item.ompetitionTime === null) {
              throw new Error('请填写比赛限时！');
            }
          }
          if (item.timeStol === null) {
            throw new Error('请选择比赛时间！');
          }
          item.startTime = item.timeStol[0];
          item.endTime = item.timeStol[1];
          if (item.shootCount) {
            item.shootCount = parseInt(item.shootCount);
          }
          item.ompetitionTime = item.ompetitionTime ? item.ompetitionTime : null
        })
      } catch (error) {
        if (error.message === '请选择赛程阶段！') {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请选择赛程阶段！'
          });
        }
        if (error.message === '请填写射击发数！') {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请填写射击发数！'
          });
        }
        if (error.message === '请填写记成绩发数！') {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请填写记成绩发数！'
          });
        }
        if (error.message === '请填写比赛限时！') {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请填写比赛限时！'
          });
        }
        if (error.message === '请选择比赛时间！') {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请选择比赛时间！'
          });
        }
      }
      // console.log(this.formData);
      // console.log(this.val);
      this.loading = true;
      const res = await updateSchedule(this.formData)
      // console.log(res);
      if (res.status === 200) {
        this.$message({
          showClose: true,
          type: 'success',
          message: '编辑成功!'
        });
        this.loading = false;
        sessionStorage.setItem('currentView', 'player');
        sessionStorage.setItem('isactive', 2);
        this.reload(); // 需要刷新页面
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: '编辑失败!' + res.msg
        });
        this.loading = false;
      }
    },
    resetForm() {
      this.$refs['elForm'].resetFields()
      this.$router.push('/home/competitionProject');
    },
  }
}
</script>

<style lang="scss" scoped>
.addRace {
  .fileBox {
    .rowBox {
      position: relative;
    }
    .addBtnBox {
      position: absolute;
      left: calc(125px + 30%);
      top: 3px;
      width: 70px;
      height: 30px;
      line-height: 30px;
      padding: 0;
      background: #e3620b;
      border-color: #e3620b;
      border-radius: 2px;
    }
    .delBtnBox {
      position: absolute;
      left: calc(200px + 30%);
      top: 3px;
      width: 70px;
      height: 30px;
      line-height: 30px;
      padding: 0;
      background: #bbbbbb;
      border-color: #bbbbbb;
      border-radius: 2px;
    }
  }
  .item1 {
    margin-bottom: 10px;
  }
  .btnBox {
    margin-top: 50px;
    ::v-deep .el-form-item__content {
      margin-left: 14% !important;
      .resBox {
        width: 70px;
        height: 30px;
        line-height: 30px;
        padding: 0;
        background: #eaefff;
        border-color: #eaefff;
        border-radius: 2px;
        span {
          color: #06206b;
        }
      }
      .subBox {
        width: 70px;
        height: 30px;
        line-height: 30px;
        padding: 0;
        background: #06206b;
        border-color: #06206b;
        border-radius: 2px;
        margin-left: 50px;
      }
    }
  }
}
</style>
